import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage
import thunk from 'redux-thunk'
import { createPromise } from 'redux-promise-middleware'
import menu from './menu'
import documents from './documents'
import accounts from './accounts'
import transcriptions from './transcriptionReview'
import translations from './translations'
import {
    DocumentsStoreType,
    AccountsStoreType,
    TranscriptionStoreType,
    TranslationStoreType,
    MenuType,
} from '../../types/reducers'

const rootReducer = combineReducers({
    menu,
    documents,
    accounts,
    transcriptions,
    translations,
})

export default function errorMiddleware() {
    return (next: any) => (action: any) => {
        // If not a promise, continue on
        if (!(action.payload instanceof Promise)) {
            return next(action)
        }
        // Dispatch initial pending promise, but catch any errors
        return next(action).catch((error: any) => {
            if (!error.response) {
                console.error(error)
            }
            return error
        })
    }
}

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [], // elements that will be persisted
    blacklist: [], // elements that will not be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    devTools: { trace: true },
    middleware: [
        errorMiddleware,
        createPromise({ promiseTypeDelimiter: '', promiseTypeSuffixes: ['Loading', 'Success', 'Error'] }),
        thunk,
    ],
})

const persistor = persistStore(store)

export interface RootState {
    menu: MenuType
    documents: DocumentsStoreType
    accounts: AccountsStoreType
    transcriptions: TranscriptionStoreType
    translations: TranslationStoreType
}

export { store, persistor }
