import { createReducer } from '@reduxjs/toolkit'
import { MenuType, ReduxActionData } from '../../types/reducers'
import { MENU_ACTIONS } from '../actions/menu'

const initialState: MenuType = {
    activeSection: null,
    pageDetails: {
        pageID: '',
        documentID: '',
    },
}

const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(MENU_ACTIONS.SET_PAGE_DETAILS, (state, action: ReduxActionData<any>) => {
            state.pageDetails = action.payload
        })
        .addCase(MENU_ACTIONS.SET_ACTIVE_SECTION, (state, action: ReduxActionData<any>) => {
            state.activeSection = action.payload
        })
)

export default dataState
