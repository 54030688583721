import { MenuSections, ReduxAction } from '../../types/reducers'

export enum MENU_ACTIONS {
    SET_ACTIVE_SECTION = 'menuActions/setActiveSection',
    SET_PAGE_DETAILS = 'menuActions/setPageDetails',
}

export const setPageDetails: ReduxAction<any> = (page: { pageID: number; documentID: number }) => {
    return {
        type: MENU_ACTIONS.SET_PAGE_DETAILS,
        payload: page,
    }
}

export const setActiveSection: ReduxAction<any> = (section: MenuSections) => {
    return {
        type: MENU_ACTIONS.SET_ACTIVE_SECTION,
        payload: section,
    }
}
