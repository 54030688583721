import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { RootState } from '../store/reducers/store'
import * as actions from '../store/actions/accounts'
import { ErrorHandling, FormikServerErrors } from '../components/general/ErrorHandling'
import { getUserInfoAction } from '../store/actions/accounts'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { NavLink } from 'react-router-dom'

const UserProfile = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state: RootState) => state.accounts.userInfo)
    const updateProfile = useSelector((state: RootState) => state.accounts.updateProfile)
    useEffect(() => {
        // noinspection PointlessBooleanExpressionJS
        if (userInfo.loading === false && userInfo.is_active && userInfo.about_me === undefined) {
            // user info is loaded but about_me is missing, load full profile
            dispatch(getUserInfoAction(true))
        }
    }, [dispatch, userInfo.loading, userInfo.about_me, userInfo.is_active])
    const UserProfileSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email address').required('Required'),
        firstName: Yup.string().max(150, 'Must be 150 characters or less'),
        lastName: Yup.string().max(150, 'Must be 150 characters or less'),
        aboutMe: Yup.string(),
    })
    if (userInfo.error || userInfo.loading || userInfo.about_me === undefined) {
        return <ErrorHandling error={userInfo.error} loading={userInfo.loading} />
    }
    return (
        <div className="container mt-4">
            <div className="row">
                <div className="col-md-10 mx-auto">
                    <div className="card">
                        <div className="card-header bg-primary text-white">
                            <h4 className="mb-0">User Profile</h4>
                        </div>
                        {updateProfile.success && (
                            <>
                                <div className="d-flex justify-content-center alert alert-success">Your profile updated successfully.</div>
                            </>
                        )}
                        <Formik
                            initialValues={{
                                username: userInfo.username,
                                email: userInfo.email,
                                firstName: userInfo.first_name || '',
                                lastName: userInfo.last_name || '',
                                aboutMe: userInfo.about_me || '',
                            }}
                            validationSchema={UserProfileSchema}
                            onSubmit={(values) => {
                                dispatch(
                                    actions.updateProfile({
                                        username: values.username,
                                        email: values.email,
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        aboutMe: values.aboutMe,
                                    })
                                )
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <FormikServerErrors errors={updateProfile.error} />
                                    <div className="card-body">
                                        <div className="row">
                                            <ErrorHandling error={updateProfile.error} loading={updateProfile.loading} />
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="username">
                                                        <strong>Username</strong>
                                                    </label>
                                                    <Field
                                                        name="username"
                                                        id="username"
                                                        className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                                                    />

                                                    <ErrorMessage name="username" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="email">
                                                        <strong>Email</strong>
                                                    </label>
                                                    <Field
                                                        name="email"
                                                        id="email"
                                                        className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
                                                    />

                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="firstName">
                                                        <strong>First name</strong>
                                                    </label>
                                                    <Field
                                                        name="firstName"
                                                        id="firstName"
                                                        className={`form-control ${touched.firstName && errors.firstName ? 'is-invalid' : ''}`}
                                                    />

                                                    <ErrorMessage name="firstName" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="lastName">
                                                        <strong>Last name</strong>
                                                    </label>
                                                    <Field
                                                        name="lastName"
                                                        id="lastName"
                                                        className={`form-control ${touched.lastName && errors.lastName ? 'is-invalid' : ''}`}
                                                    />

                                                    <ErrorMessage name="lastName" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col">
                                                <div className="form-outline mb-4">
                                                    <label className="form-label" htmlFor="aboutMe">
                                                        <strong>About me</strong>
                                                    </label>
                                                    <Field
                                                        name="aboutMe"
                                                        id="aboutMe"
                                                        className={`form-control ${touched.aboutMe && errors.aboutMe ? 'is-invalid' : ''}`}
                                                    />

                                                    <ErrorMessage name="aboutMe" component="div" className="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col-md-6">
                                                <p className="mb-1">
                                                    <strong>Score:</strong> {userInfo.user_score}
                                                </p>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row">
                                            <div className="col">
                                                <p className="mb-1">
                                                    <strong>Contributions:</strong>
                                                </p>
                                                <ol>
                                                    {userInfo.user_contributions?.map((page) => (
                                                        <li key={page.pk}>
                                                            <NavLink to={`/book/${page.document_id}/${page.pk}`}>
                                                                {`Page ${page.number} of ${page.document_title}`}
                                                            </NavLink>
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-outline my-5 text-center">
                                        <button className="btn btn-primary btn-lg " type="submit">
                                            Update Profile
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile
