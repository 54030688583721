import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../store/actions/transcriptionReview'
import { getPageDetails } from '../store/actions/documents'
import { RootState } from '../store/reducers/store'
import { ROUTES } from '../resources/routes-constants'
import { LineData, MenuSections, TranscriptionStoreType } from '../types/reducers'
import { useParams, generatePath, NavLink } from 'react-router-dom'
import LineTranscriptions from '../components/transcription/LineTranscriptions'
import { ErrorHandling } from '../components/general/ErrorHandling'
import { BackToPageDetailsButton } from '../components/documents/PageNavigation'
import { setActiveSection, setPageDetails } from '../store/actions/menu'

const TranscriptionReview = () => {
    const dispatch = useDispatch()
    const userCanReview = useSelector((state: RootState) => state.accounts.userInfo.is_staff)
    const transcriptions: TranscriptionStoreType = useSelector((state: RootState) => state.transcriptions)
    const curDocument = useSelector((state: RootState) => state.documents.curDocument)
    const params = useParams()
    const curPageId: string = params.page!
    const documentId: string = params.id!
    const page = curDocument?.pages[curPageId]
    const [error, setError] = useState<string>('')

    const highlightLine = useCallback((lineData: LineData) => {
        const pageEl = window.document.getElementById('page-image-container')
        const image: HTMLImageElement = window.document.getElementById('page-image') as HTMLImageElement
        if (pageEl && image) {
            let yPos = lineData.box[1] || 0
            yPos = yPos * (image.height / image.naturalHeight) - image.parentElement!.clientHeight / 2
            pageEl.scrollTo(0, yPos)
        }
    }, [])

    useEffect(() => {
        dispatch(setPageDetails({ pageID: params.page || null, documentID: params.id }))
        dispatch(setActiveSection(MenuSections.TranscriptionReview))
    }, [dispatch, params])

    useEffect(() => {
        if (!page) {
            dispatch(getPageDetails(curPageId))
        }
    }, [page, curPageId, dispatch])

    useEffect(() => {
        if (page) {
            const lineData = page.lines_data![transcriptions.currentLineIndex]
            highlightLine(lineData)
        }
    }, [page, highlightLine, transcriptions.currentLineIndex])

    useEffect(() => {
        if (transcriptions.loading === null || (!transcriptions.loading && transcriptions.pageId !== curPageId)) {
            dispatch(actions.getTranscriptions(curPageId))
        }
    }, [transcriptions, curPageId, dispatch])

    useEffect(() => {
        if (transcriptions.reviewResponse.isSubmitted) {
            dispatch(getPageDetails(curPageId))
        }
    }, [transcriptions.reviewResponse.isSubmitted, curPageId, dispatch])

    const changeInPreviewPage = (step: string) => {
        switch (step) {
            case 'previous':
                if (transcriptions.currentLineIndex > 0) {
                    dispatch(actions.setCurrentLineIndex(transcriptions.currentLineIndex - 1))
                }
                break
            case 'next':
                if (transcriptions.currentLineIndex < transcriptions.transcriptions.length - 1) {
                    dispatch(actions.setCurrentLineIndex(transcriptions.currentLineIndex + 1))
                }
                break
            default:
                return
        }
    }

    const submitReview = () => {
        let missingLines = false
        dispatch(actions.setCurrentLineIndex(0))
        const text = transcriptions.transcriptions
            .map((entry) => {
                if (entry.selected === null) {
                    missingLines = true
                    return ''
                }
                if (entry.selected === -1) {
                    return entry.customText.replaceAll('\n', '')
                }
                return entry.line_transcriptions[entry.selected]['transcription']
            })
            .join('\n')

        if (missingLines) {
            setError('There are some lines that you have not selected a transcription for.')
        } else {
            dispatch(actions.submitTranscriptionReview({ page: page, text: text }))
        }
        return missingLines
    }

    if (!userCanReview) {
        return <ErrorHandling error={'You do not have permission to review pages'} />
    }
    if (!page) {
        return <ErrorHandling error="" loading={true} />
    }
    if (!transcriptions.transcriptions.length) {
        if (transcriptions.loading || transcriptions.loading === undefined) {
            return <ErrorHandling error="" loading={true} />
        }
        return <div>No transcriptions available.</div>
    }
    const lineData = page.lines_data![transcriptions.currentLineIndex]
    highlightLine(lineData)
    return (
        <div>
            <div className="row">
                <div className="col-2">
                    <BackToPageDetailsButton />
                </div>
                <div className="col-8">
                    <h1 className="m-3 text-center">Transcription Review</h1>
                </div>
                <div className="col-2">
                    <NavLink
                        to={generatePath(ROUTES.DOCUMENT_REVIEW_ROUTE, {
                            id: documentId.toString(),
                            page: transcriptions.nextPageId?.toString() || '0',
                        })}
                        className={
                            'btn btn-sm btn-outline-secondary m-3 active' +
                            (transcriptions.nextPageId ? '' : ' disabled')
                        }
                    >
                        Next Review &gt;
                    </NavLink>
                </div>
            </div>
            {page.complete && (
                <div className="alert alert-info m-3">
                    This page has already been reviewed and marked as &ldquo;complete&ldquo;.
                </div>
            )}
            <ErrorHandling error={transcriptions.error} loading={transcriptions.loading} />
            <ErrorHandling error={error} />
            <div className="col-12" id="page-image-container">
                <span className="badge rounded-pill bg-warning" id="marker">
                    &nbsp;
                </span>
                <img src={page.url} alt={'Page image'} id="page-image" className="img img-responsive" />
                {/* Add extra white space below the image to make scrolling near the bottom accurate */}
                <div id="page_bottom_spacer"></div>
            </div>
            {page.complete ? null : <LineTranscriptions />}
            <div id="LineNavigationDiv" className="mt-3">
                <button
                    className="btn btn-secondary mx-2"
                    onClick={() => changeInPreviewPage('previous')}
                    disabled={transcriptions.currentLineIndex === 0}
                >
                    Previous
                </button>
                <button
                    className="btn btn-secondary mx-2"
                    onClick={() => changeInPreviewPage('next')}
                    disabled={
                        transcriptions.currentLineIndex === transcriptions.transcriptions.length - 1 ||
                        transcriptions.transcriptions[transcriptions.currentLineIndex].selected === null
                    }
                >
                    Next
                </button>
                {transcriptions.currentLineIndex === transcriptions.transcriptions.length - 1 && (
                    <button
                        className="btn btn-primary mx-2"
                        onClick={() => submitReview()}
                        disabled={
                            transcriptions.transcriptions[transcriptions.currentLineIndex].selected === null ||
                            transcriptions.reviewResponse.isSubmitted === 1
                        }
                    >
                        {' '}
                        Submit Review{' '}
                    </button>
                )}
            </div>

            {transcriptions.reviewResponse.isSubmitted === 1 && (
                <div className="mt-3 alert alert-success col-12">
                    Review submitted successfully!
                    <br />
                    {transcriptions.nextPageId !== null ? (
                        <>You can navigate to the next page and review its transcription</>
                    ) : (
                        'No more pages left to review.'
                    )}
                </div>
            )}
        </div>
    )
}

export default TranscriptionReview
