import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../store/actions/documents'
import { RootState } from '../../store/reducers/store'
import { generatePath, NavLink } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'
import { ErrorHandling } from '../general/ErrorHandling'
import { AddDocument } from './AddDocument'

export const DocumentGallery: React.FC = () => {
    const dispatch = useDispatch()
    const documents = useSelector((state: RootState) => state.documents.documents)
    const loading = useSelector((state: RootState) => state.documents.loading)
    const error = useSelector((state: RootState) => state.documents.error)

    useEffect(() => {
        if (loading === null) {
            dispatch(actions.listDocumentsAction())
        }
    }, [dispatch, loading])

    return (
        <div className="container-fluid bg-white">
            <ErrorHandling error={error} loading={loading} />
            <div className="row">
                {Object.values(documents).map((document) => {
                    if (!document || !document.pk) {
                        return null
                    }
                    const hasPages = document.first_page_id !== null

                    return (
                        <div className="col-xs-6 col-md-4 col-lg-3 col-xl-2" key={document.pk}>
                            <div className="book-container">
                                <img
                                    src={document.image}
                                    alt={`${document.title} Image`}
                                    className="img img-responsive book-image"
                                />
                                <div>
                                    <NavLink
                                        className={hasPages ? '' : 'disabled-link'}
                                        to={generatePath(ROUTES.DOCUMENT_DETAILS_ROUTE, {
                                            id: document.pk.toString(),
                                            page: document.first_page_id ? document.first_page_id.toString() : '',
                                        })}
                                    >
                                        {document.title}
                                    </NavLink>
                                    {hasPages ? '' : <span className="badge bg-secondary">(Pending Review)</span>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <AddDocument />
        </div>
    )
}
