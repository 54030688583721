import { ReduxAction, PageType } from '../../types/reducers'
import axios, { AxiosPromise } from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export enum PAGE_REVIEW_ACTIONS {
    SUBMIT_REVIEW = 'pageReviewActions/submitReview',
    SUBMIT_REVIEW_LOADING = 'pageReviewActions/submitReviewLoading',
    SUBMIT_REVIEW_LOADED = 'pageReviewActions/submitReviewSuccess',
    SUBMIT_REVIEW_ERROR = 'pageReviewActions/submitReviewError',
    GET_TRANSCRIPTIONS = 'pageReviewActions/getTranscriptions',
    GET_TRANSCRIPTIONS_LOADING = 'pageReviewActions/getTranscriptionsLoading',
    GET_TRANSCRIPTIONS_LOADED = 'pageReviewActions/getTranscriptionsSuccess',
    GET_TRANSCRIPTIONS_ERROR = 'pageReviewActions/getTranscriptionsError',
    SET_SELECTED_INDEX = 'pageReviewActions/setSelectedIndex',
    SET_CUSTOM_TEXT = 'pageReviewActions/setCustomText',
    SET_CURRENT_LINE_INDEX = 'pageReviewActions/setCurrentLineIndex',
}

export const getTranscriptions: ReduxAction<Promise<AxiosPromise>> = (pageId) => {
    return {
        type: PAGE_REVIEW_ACTIONS.GET_TRANSCRIPTIONS,
        payload: axios.get(`/b/ocr/api/pages/${pageId}/transcriptions/`),
        meta: { pageId },
    }
}

export const setSelectedIndex = (index: number | null) => ({
    type: PAGE_REVIEW_ACTIONS.SET_SELECTED_INDEX,
    payload: { index },
})

export const setCurrentLineIndex = (currentLineIndex: number) => {
    return {
        type: PAGE_REVIEW_ACTIONS.SET_CURRENT_LINE_INDEX,
        payload: { currentLineIndex },
    }
}

export const setCustomText = (customText: string) => ({
    type: PAGE_REVIEW_ACTIONS.SET_CUSTOM_TEXT,
    payload: { customText },
})

export const submitTranscriptionReview: ReduxAction<Promise<AxiosPromise>> = (data: {
    text: string
    page: PageType
}) => {
    const { page, text } = data
    return {
        type: PAGE_REVIEW_ACTIONS.SUBMIT_REVIEW,
        payload: axios.post(`/b/ocr/api/pages/${page.pk}/submit_transcription_review/`, { text }),
        meta: { pageId: page.pk, text },
    }
}
