import { OCR_ACTIONS } from '../actions/documents'
import { createReducer } from '@reduxjs/toolkit'
import { DocumentDetailsType, DocumentsStoreType, DocumentType, PageType, ReduxActionData } from '../../types/reducers'

const initialState: DocumentsStoreType = {
    documents: {},
    curDocument: { pk: 0, title: '', url: '', description: '', pages: {}, loading: null, error: null },
    createDocument: { loading: null, error: null },
    pageViewMode: 'edit',
    loading: null,
    error: null,
}

const getPage = (state: DocumentsStoreType, pageId: number): PageType => {
    // some components may not have the current document loaded (for example page review)
    return (
        state.curDocument?.pages[pageId] || {
            pk: pageId,
            transcription: '',
            draft: '',
            submitted: false,
            loading: null,
            error: null,
        }
    )
}

const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(OCR_ACTIONS.LIST_DOCUMENTS_LOADING, (state) => {
            state.loading = true
            state.error = null
        })
        .addCase(OCR_ACTIONS.LIST_DOCUMENTS_LOADED, (state, action: ReduxActionData<any>) => {
            state.loading = false
            state.documents = Object.fromEntries(action.payload.data.map((doc: DocumentType) => [doc.pk, doc]))
        })
        .addCase(OCR_ACTIONS.LIST_DOCUMENTS_ERROR, (state = initialState, action: ReduxActionData<any>) => {
            state.error = action.payload.response.data
            state.loading = false
        })
        .addCase(OCR_ACTIONS.GET_DOCUMENT_DETAILS_LOADING, (state) => {
            state.curDocument.error = null
            state.curDocument.loading = true
        })
        .addCase(OCR_ACTIONS.GET_DOCUMENT_DETAILS_LOADED, (state, action: ReduxActionData<any>) => {
            const curDocument: unknown = action.payload.data
            // @ts-ignore
            curDocument.pages = Object.fromEntries(curDocument.pages.map((page: PageType) => [page.pk, page]))
            state.curDocument = curDocument as DocumentDetailsType
            state.curDocument.loading = false
        })
        .addCase(OCR_ACTIONS.LIST_DOCUMENT_DETAILS_ERROR, (state = initialState, action: ReduxActionData<any>) => {
            state.curDocument.error = action.payload.response.data
            state.curDocument.loading = false
        })
        .addCase(OCR_ACTIONS.CREATE_DOCUMENT_LOADING, (state) => {
            state.createDocument.loading = true
            state.createDocument.error = null
        })
        .addCase(OCR_ACTIONS.CREATE_DOCUMENT_LOADED, (state, action: ReduxActionData<any>) => {
            state.createDocument.loading = false
            state.documents[action.payload.data.pk] = action.payload.data
        })
        .addCase(OCR_ACTIONS.CREATE_DOCUMENT_ERROR, (state = initialState, action: ReduxActionData<any>) => {
            state.createDocument.error = action.payload.response.data
            state.createDocument.loading = false
        })
        .addCase(OCR_ACTIONS.GET_PAGE_DETAILS_LOADING, (state, action: ReduxActionData<any>) => {
            const page = getPage(state, action.meta.pageId)
            page.error = null
            page.loading = true
        })
        .addCase(OCR_ACTIONS.GET_PAGE_DETAILS_LOADED, (state, action: ReduxActionData<any>) => {
            const page: PageType = action.payload.data
            page.loading = false
            page.draft = page.transcription || page.ocr_text || ''
            state.curDocument.pages[page.pk] = page
        })
        .addCase(OCR_ACTIONS.GET_PAGE_DETAILS_ERROR, (state, action: ReduxActionData<any>) => {
            const page = getPage(state, action.meta.pageId)
            page.error = action.payload.response.data
            page.loading = false
        })
        .addCase(OCR_ACTIONS.SET_PAGE_VIEW_MODE, (state, action: ReduxActionData<any>) => {
            state.pageViewMode = action.payload
        })
        .addCase(OCR_ACTIONS.SAVE_TRANSCRIPTION_LOADING, (state, action: ReduxActionData<any>) => {
            const page = getPage(state, action.meta.pageId)
            page.loading = true
            page.error = null
        })
        .addCase(OCR_ACTIONS.SAVE_TRANSCRIPTION_LOADED, (state, action: ReduxActionData<any>) => {
            const page = getPage(state, action.meta.pageId)
            page.loading = false
            page.transcription = action.meta.text
            page.submitted = action.meta.status === 'submitted'
        })
        .addCase(OCR_ACTIONS.SAVE_TRANSCRIPTION_ERROR, (state, action: ReduxActionData<any>) => {
            const page = getPage(state, action.meta.pageId)
            page.error = action.payload.response.data.detail
            page.loading = false
        })
        .addCase(OCR_ACTIONS.SAVE_DRAFT, (state, action: ReduxActionData<any>) => {
            const page = state.curDocument!.pages[action.payload.pageId]
            page.draft = action.payload.draft
        })
)

export default dataState
