import { Dictionary } from '@reduxjs/toolkit'

export interface GenericAPIResponseType {
    loading: boolean | null
    success?: boolean | null
    error: string | { [key: string]: string } | null
}

export interface ReviewResponseType {
    isSubmitted: number
    error: string | { [key: string]: string } | null
}
export interface TranscriptionLineType {
    username: string
    modified: string
    transcription: string
}
export interface TranscriptionType {
    customText: string
    line_transcriptions: TranscriptionLineType[]
    selected: number | null
}
export interface RawTranscriptionType {
    username: string
    text: string
    modified: string
}
export interface TranscriptionStoreType extends GenericAPIResponseType {
    transcriptions: TranscriptionType[]
    reviewResponse: ReviewResponseType
    pageId: string | null
    nextPageId: number | null
    currentLineIndex: number
}

export interface DocumentType {
    pk: number
    title: string
    description: string
    image: string
    first_page_id: number
}
export interface LineData {
    box: number[]
}
export interface PageType extends GenericAPIResponseType {
    pk: number
    number: number
    url: string
    modified: boolean
    transcription?: string
    best_transcription?: string
    ocr_text?: string
    draft?: string
    lines_data?: LineData[]
    complete?: boolean
    next_page_complete?: boolean
    submitted?: boolean
    total_number_of_transcriptions?: number
    textForTranslating?: string
    translation?: string
}

export interface PageListingType {
    [key: string]: PageType
}

export interface DocumentDetailsType extends GenericAPIResponseType {
    pk: number
    title: string
    url: string
    description: string
    pages: PageListingType
}

export interface DocumentsStoreType extends GenericAPIResponseType {
    documents: Dictionary<DocumentType>
    curDocument: DocumentDetailsType
    createDocument: GenericAPIResponseType
    pageViewMode: string
}
export interface LoginType extends GenericAPIResponseType {
    showLoginForm: boolean
}

export interface UserInfoType extends GenericAPIResponseType {
    username?: string
    first_name?: string
    last_name?: string
    email?: string
    is_active: boolean | null
    is_staff: boolean | null
    about_me?: string
    user_score?: number
    user_contributions?: UserContributionType[]
}
export interface AccountsStoreType {
    login: LoginType
    register: GenericAPIResponseType
    logout: GenericAPIResponseType
    updateProfile: GenericAPIResponseType
    userInfo: UserInfoType
}

export interface UserContributionType {
    pk: number
    number: number
    url: string
    document_id: number
    document_title: string
}

export enum MenuSections {
    OCRPage = 1,
    TranscriptionReview,
    Translation,
}

export interface MenuType {
    activeSection: MenuSections | null
    pageDetails: {
        pageID: string
        documentID: string
    }
}

export type ReduxActionData<T> = {
    type: any
    payload?: T
    meta?: any
}
export type TranslationTabType = 'organize' | 'translate'

export interface LineTranslationType {
    text: string
    translation: string
    comment: string
}

export interface TranslationEntryType extends GenericAPIResponseType {
    lines: LineTranslationType[]
    submitTranslation: GenericAPIResponseType
    nextPageLines: string
    activeTab: TranslationTabType
    originalText: string
}
export interface TranslationStoreType {
    [pageId: string]: TranslationEntryType
}

export type ReduxAction<T> = (data?: any) => ReduxActionData<T>
