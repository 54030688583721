import React from 'react'
import { useFormikContext } from 'formik'

interface ErrorHandlingProps {
    error: any
    loading?: boolean | null
}

export const ErrorHandling: React.FC<ErrorHandlingProps> = ({ error, loading }) => {
    let errorString: string

    if (loading) {
        return (
            <div className="alert alert-info col-2 offset-5  error-handling-loading">
                <div className="spinner-border spinner-border-sm" role="status"></div>
                <span className="ms-2">Loading ...</span>
            </div>
        )
    }
    // If there is no error, render nothing
    if (!error) return null

    if (typeof error === 'string') {
        // If error is already a string, use it directly
        errorString = error
    } else if (typeof error === 'object') {
        // Check for common error keys, this list will be extended based on the needs
        const commonErrorKeys = ['detail', 'message', 'error', 'non_field_errors']
        const foundKey = commonErrorKeys.find((key) => key in error)

        if (foundKey) {
            // If a common error key is found, use its value
            errorString = error[foundKey]
        } else {
            // If no common key is found, don't handle it
            return null
        }
    } else {
        // Fallback for unknown types
        errorString = JSON.stringify(error)
    }

    return (
        <div className="alert alert-warning col-12">
            {errorString.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line} <br />
                </React.Fragment>
            ))}
        </div>
    )
}

interface FormikServerErrorsProps {
    errors: string | { [key: string]: string } | null
    loading?: boolean | null
}
export const FormikServerErrors = (props: FormikServerErrorsProps) => {
    const { setErrors, setSubmitting } = useFormikContext()
    React.useEffect(() => {
        setSubmitting(props.loading === true)
    }, [setSubmitting, props.loading])

    React.useEffect(() => {
        if (props.errors) {
            setErrors(props.errors)
        }
    }, [setErrors, props.errors])
    return null
}
