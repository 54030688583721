import React, { useEffect } from 'react'
import { DocumentGallery } from '../components/documents/DocumentGallery'
import { setActiveSection } from '../store/actions/menu'
import { useDispatch } from 'react-redux'

const HomePage: React.FC = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setActiveSection(null))
    }, [dispatch])
    return (
        <div className="text-center">
            <h1>Coptic Books</h1>
            <DocumentGallery />
        </div>
    )
}

export default HomePage
