import React, { ChangeEventHandler, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import * as actions from '../../store/actions/translations'
import { ErrorHandling } from '../general/ErrorHandling'

const organizeText = (inputText: string) => {
    let combinedText = inputText.replaceAll('\n', ' ').trim()

    // Step 2: Remove dashes and combine the words around the dash
    combinedText = combinedText.replace(/ *- */g, '')

    // Step 3: Separate the lines using dots
    const lines = combinedText.split('.').map((line) => line.trim())
    return lines.join('.\n')
}

export const TextOrganizer = () => {
    const params = useParams()
    const pageID: string = params.page!
    const translation = useSelector((state: RootState) => state.translations[pageID] || {})
    const activeTab = useSelector((state: RootState) => state.translations[pageID]?.activeTab)
    const originalText: string = translation.originalText || ''
    const [text, setText] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        setText(originalText)
    }, [originalText])

    useEffect(() => {
        if (text && activeTab !== 'organize') {
            dispatch(actions.setOrganizedTranslationText(text, pageID))
        }
    }, [activeTab, dispatch, text, pageID])

    if (!params.page) {
        return <div>Incorrect URL</div>
    }
    const onTextChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setText(e.target.value)
    }

    if (translation.originalText === undefined) {
        return <ErrorHandling error="" loading={true} />
    }

    return (
        <div className="container">
            <ErrorHandling error={translation.error} loading={translation.loading} />
            <div className="row">
                <button className="btn btn-primary col" onClick={() => setText(organizeText(text))}>
                    Organize
                </button>
                <button className="btn btn-secondary col" onClick={() => setText(originalText || '')}>
                    Reset
                </button>
            </div>
            <div className="row">
                <textarea className="form-control fs-4" rows={8} value={text} onChange={onTextChange} />
            </div>
            {translation.nextPageLines && <pre className="row my-3 fs-5">{translation.nextPageLines}</pre>}
        </div>
    )
}
