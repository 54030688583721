import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../store/reducers/store'
import * as actions from '../../store/actions/translations'
import TranslationLine from './TranslationLine'
import { ErrorHandling } from '../general/ErrorHandling'
import { createSelector } from '@reduxjs/toolkit'

export const TextTranslator = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const pageId = params.page || ''
    const { submitTranslation, lines } = useSelector(
        createSelector(
            (state: RootState) => state.translations[pageId] || null,
            (translations) => {
                return {
                    submitTranslation: translations.submitTranslation,
                    lines: translations.lines,
                }
            }
        )
    )
    const renderedTranslations = []
    const onSubmitTranslation = () => {
        dispatch(actions.submitTranslations({ pageId: pageId, lines: lines }))
    }
    if (lines && lines.length) {
        for (let i = 0; i < lines.length; i++) {
            renderedTranslations.push(<TranslationLine key={i} lineIndex={i} />)
        }
    }
    return (
        <div className="container">
            <ErrorHandling error={submitTranslation.error} loading={submitTranslation.loading} />
            {submitTranslation.success ? (
                <div className="d-flex justify-content-center alert alert-success">
                    Your translations submitted successfully.
                </div>
            ) : null}
            {renderedTranslations}
            <div className="row">
                <hr />
                <button className="btn btn-primary col mb-4" onClick={onSubmitTranslation}>
                    Submit
                </button>
            </div>
        </div>
    )
}
