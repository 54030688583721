import React, { useEffect } from 'react'
import { BrowserRouter as Router, NavLink, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { Document } from './pages/Document'
import TranscriptionReview from './pages/TranscriptionReview'
import Translation from './pages/Translation'
import { Register } from './pages/Register'
import { ROUTES } from './resources/routes-constants'
import './styles/main.sass'
import 'bootstrap'
import * as actions from './store/actions/accounts'
import { getUserInfoAction, showLoginForm } from './store/actions/accounts'
import { RootState } from './store/reducers/store'
import UserProfile from './pages/UserProfile'
import { LoginModal } from './components/account/Login'
import { MenuSections } from './types/reducers'
import { PageDetailsMenu } from './components/documents/PageMenu'

const AccountsMenu: React.FC = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state: RootState) => state.accounts.userInfo)
    useEffect(() => {
        if (userInfo.loading === null) {
            dispatch(getUserInfoAction(false))
        }
    }, [dispatch, userInfo.loading])

    const logout = () => {
        dispatch(actions.logoutUser())
    }

    if (userInfo.loading) return <li>Loading ...</li>
    return (
        <li className="nav-item dropdown">
            <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                Account
            </a>
            <ul className="dropdown-menu">
                {userInfo.is_active ? (
                    <>
                        <li>
                            <NavLink className="nav-link" to={ROUTES.USER_PROFILE_ROUTE}>
                                {`${userInfo.username} Profile`}
                            </NavLink>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <button onClick={() => logout()} className="dropdown-item">
                                Logout
                            </button>
                        </li>
                    </>
                ) : (
                    <>
                        <li>
                            <button onClick={() => dispatch(showLoginForm(true))} className="dropdown-item">
                                Login
                            </button>
                        </li>
                        <li>
                            <NavLink className="nav-link" to={ROUTES.REGISTER_ROUTE}>
                                Register
                            </NavLink>
                        </li>
                    </>
                )}
            </ul>
            <LoginModal />
        </li>
    )
}

const Menu: React.FC = () => {
    const activeSection = useSelector((state: RootState) => state.menu.activeSection)
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to={ROUTES.HOMEPAGE_ROUTE}>
                                Home
                            </NavLink>
                        </li>
                        <AccountsMenu />
                        {activeSection == MenuSections.OCRPage ? <PageDetailsMenu /> : null}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

const RootComponent: React.FC = () => {
    return (
        <div>
            <Router>
                <Menu />
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={ROUTES.REGISTER_ROUTE} element={<Register />} />
                    <Route path={ROUTES.USER_PROFILE_ROUTE} element={<UserProfile />} />
                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                    <Route path={ROUTES.DOCUMENT_DETAILS_ROUTE} element={<Document />} />
                    <Route path={ROUTES.DOCUMENT_REVIEW_ROUTE} element={<TranscriptionReview />} />
                    <Route path={ROUTES.DOCUMENT_TRANSLATE_ROUTE} element={<Translation />} />
                </Routes>
            </Router>
        </div>
    )
}

export default RootComponent
