const NEWLINE_RE = new RegExp(/\n/, 'gm')

export const getCurrentLine = (text: string, caret: number): { curLine: number; linesCount: number } => {
    let curLine = 0
    const allLines = [...text.matchAll(NEWLINE_RE)]
    // Add one to account for the last line that doesn't have a \n
    const linesCount = allLines.length + 1
    for (const line of allLines) {
        if (line.index! >= caret) {
            return { curLine, linesCount }
        }
        curLine += 1
    }
    return { curLine, linesCount }
}
