import React, { useEffect, useState } from 'react'
import { TranscriptionType, TranscriptionLineType } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import * as actions from '../../store/actions/transcriptionReview'
import { Editor } from '../general/CopticEditor'

function formatDate(timestamp: string): string {
    const date = new Date(timestamp)
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
}
const LineTranscriptions = () => {
    const dispatch = useDispatch()
    const line: TranscriptionType = useSelector(
        (state: RootState) => state.transcriptions.transcriptions[state.transcriptions.currentLineIndex]
    )
    const [text, setText] = useState('')
    const [editorInitialized, setEditorInitialized] = useState(false)
    useEffect(() => {
        if (line) {
            setText(line.customText)
        }
    }, [line])

    const onEditorInit = () => {
        if (!line) return
        const editorText = line.customText || ''
        setText(editorText)
        setEditorInitialized(true)
    }
    const handleTranscriptionSelectionChange = (index: number) => {
        dispatch(actions.setSelectedIndex(index))
    }

    const handleCustomTextSelection = (customText: string) => {
        dispatch(actions.setCustomText(customText))
    }

    const selectedIndex: number | null = line.selected === null ? null : Number(line.selected)
    const customText: string = line.customText

    const useCustomText = line.selected === -1
    return (
        <div>
            {line.line_transcriptions.map((lineItem: TranscriptionLineType, index: number) => (
                <div key={lineItem.modified}>
                    <label
                        className="ms-3 h3"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-placement="top"
                        htmlFor={`transcriptionRadio_${index}`}
                        title={`Username: ${lineItem.username} \nModified: ${formatDate(lineItem.modified)}`}
                        onClick={() => {
                            handleTranscriptionSelectionChange(index)
                        }}
                    >
                        <input
                            className="me-2 mt-3"
                            type="radio"
                            name={`transcriptionRadio_${index}`}
                            checked={selectedIndex === index}
                            readOnly={true}
                        />
                        {lineItem.transcription}
                    </label>
                </div>
            ))}
            <label
                className="ms-3 h3"
                data-bs-toggle="tooltip"
                data-bs-html="true"
                data-bs-placement="top"
                htmlFor="customTextRadio"
                onClick={() => {
                    handleTranscriptionSelectionChange(-1)
                }}
            >
                <input
                    className="me-2 mt-3"
                    type="radio"
                    name="customTextRadio"
                    checked={selectedIndex === -1}
                    onClick={() => {
                        handleCustomTextSelection(customText)
                    }}
                    readOnly={true}
                />
                Input custom text: <span className="text-muted">{customText}</span>
            </label>

            <Editor
                className={
                    'col-12 editor_iframe_1 embed-responsive ' + (useCustomText && editorInitialized ? '' : ' d-none')
                }
                title="Coptic Editor"
                text={text}
                rows={1}
                onInit={onEditorInit}
                onTextChanged={handleCustomTextSelection}
            />
            {useCustomText && !editorInitialized ? <div className="alert alert-info">Loading editor...</div> : null}
        </div>
    )
}

export default LineTranscriptions
