import { PAGE_REVIEW_ACTIONS } from '../actions/transcriptionReview'
import { createReducer } from '@reduxjs/toolkit'
import {
    TranscriptionStoreType,
    ReduxActionData,
    RawTranscriptionType,
    TranscriptionLineType,
} from '../../types/reducers'

const initialState: TranscriptionStoreType = {
    transcriptions: [],
    loading: null,
    error: null,
    currentLineIndex: 0,
    pageId: null,
    nextPageId: null,
    reviewResponse: {
        isSubmitted: 0,
        error: null,
    },
}

const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(PAGE_REVIEW_ACTIONS.SET_CURRENT_LINE_INDEX, (state, action: ReduxActionData<any>) => {
            const { currentLineIndex } = action.payload
            state.currentLineIndex = currentLineIndex
        })
        .addCase(PAGE_REVIEW_ACTIONS.GET_TRANSCRIPTIONS_LOADING, (state, action: ReduxActionData<any>) => {
            state.loading = true
            state.error = null
            state.pageId = action.meta.pageId
        })
        .addCase(
            PAGE_REVIEW_ACTIONS.GET_TRANSCRIPTIONS_LOADED,
            (state = initialState, action: ReduxActionData<any>) => {
                state.transcriptions = []
                state.loading = false
                state.error = null

                const transcriptions: RawTranscriptionType[] = action.payload.data.rows

                if (transcriptions.length === 0) return

                const allText: string[][] = transcriptions.map((transcription) => transcription.text.split('\n'))
                const numberOfLines = allText[0].length

                for (let lineIndex = 0; lineIndex < numberOfLines; lineIndex++) {
                    const lineTranscriptions: TranscriptionLineType[] = []

                    // Create a Set to store unique transcriptions for this line
                    const uniqueTranscriptions = new Set()

                    for (let transcriptionIndex = 0; transcriptionIndex < transcriptions.length; transcriptionIndex++) {
                        const transcriptionText = allText[transcriptionIndex][lineIndex]

                        // Check if the transcription is not already in the Set
                        if (uniqueTranscriptions.has(transcriptionText)) {
                            continue
                        }
                        lineTranscriptions.push({
                            username: transcriptions[transcriptionIndex].username,
                            modified: transcriptions[transcriptionIndex].modified,
                            transcription: transcriptionText,
                        })

                        // Add the transcription to the Set
                        uniqueTranscriptions.add(transcriptionText)
                    }

                    const customText = lineTranscriptions.length > 0 ? lineTranscriptions[0].transcription : ''
                    const selected = lineTranscriptions.length > 0 ? 0 : null
                    state.transcriptions.push({ customText, line_transcriptions: lineTranscriptions, selected })
                }
                state.reviewResponse.isSubmitted = 0
                state.nextPageId = action.payload.data.next_page_id
            }
        )
        .addCase(PAGE_REVIEW_ACTIONS.GET_TRANSCRIPTIONS_ERROR, (state = initialState, action: ReduxActionData<any>) => {
            state.error = action.payload.response.data
            state.loading = false
        })
        .addCase(PAGE_REVIEW_ACTIONS.SET_SELECTED_INDEX, (state, action: ReduxActionData<any>) => {
            const { index } = action.payload
            state.transcriptions[state.currentLineIndex].selected = index
        })
        .addCase(PAGE_REVIEW_ACTIONS.SET_CUSTOM_TEXT, (state, action: ReduxActionData<any>) => {
            const { customText } = action.payload
            state.transcriptions[state.currentLineIndex].customText = customText
            state.transcriptions[state.currentLineIndex].selected = -1
        })
        .addCase(PAGE_REVIEW_ACTIONS.SUBMIT_REVIEW_LOADING, (state) => {
            state.error = null
            state.loading = true
        })
        .addCase(PAGE_REVIEW_ACTIONS.SUBMIT_REVIEW_LOADED, (state) => {
            state.reviewResponse.isSubmitted = 1
            state.loading = false
        })
        .addCase(PAGE_REVIEW_ACTIONS.SUBMIT_REVIEW_ERROR, (state, action: ReduxActionData<any>) => {
            state.error = action.payload.response.data
            state.loading = false
        })
)
export default dataState
