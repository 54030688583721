import React, { RefObject } from 'react'

const EDITOR_DOMAIN = 'https://editor.danacbe.com'

type EditorPropsType = {
    text: string
    rows: number
    placeholder: string
    readonly: boolean
    className: string
    title: string
    onInit?: {
        (): void
    }
    onTextChanged?: {
        (text: string): void
    }
    onCaretPositionChanged?: {
        (caretPosition: number): void
    }
    onBlur?: {
        (): void
    }
}

export class Editor extends React.Component<EditorPropsType, { text: string }> {
    static defaultProps = {
        text: '',
        rows: 4,
        placeholder: '',
        readonly: false,
        className: '',
        title: '',
    }
    iframe = React.createRef() as RefObject<HTMLIFrameElement>

    constructor(props: EditorPropsType) {
        super(props)
        this.state = {
            text: '',
        }
    }

    componentDidMount = () => {
        window.addEventListener('message', this.onMessage)
    }

    componentWillUnmount = () => {
        window.removeEventListener('message', this.onMessage)
    }

    setReadOnly = (readOnly: boolean) => {
        const editorWin = this.getEditorIFrame()
        if (!editorWin) {
            return
        }
        editorWin.postMessage({ type: 'setReadOnly', value: readOnly }, '*')
    }

    getEditorIFrame = () => {
        return this.iframe.current?.contentWindow
    }

    setText = (text: string) => {
        const editorWin = this.getEditorIFrame()
        if (!editorWin) {
            return
        }
        editorWin.postMessage({ type: 'setText', value: text }, '*')
        this.setState({ text: text })
    }

    onMessage = (event: MessageEvent) => {
        if (event.origin !== EDITOR_DOMAIN) return
        const eventType = event.data.type
        switch (eventType) {
            case 'init': {
                this.setText(this.props.text)
                this.setReadOnly(this.props.readonly)
                if (this.props.onInit) {
                    this.props.onInit()
                }
                break
            }
            case 'textChanged': {
                this.setState({ text: event.data.value })
                if (this.props.onTextChanged) {
                    this.props.onTextChanged(event.data.value)
                }
                break
            }
            case 'caretPositionChanged': {
                this.props.onCaretPositionChanged!(event.data.value)
                break
            }
            case 'blur': {
                this.props.onBlur!()
                break
            }
        }
    }

    buildEditorUrl = (props: EditorPropsType) => {
        let editorUrl = `${EDITOR_DOMAIN}/?ws=coptic&embed=1&fixedRows=1&rows=${props.rows}&placeholder=${props.placeholder}`
        if (props.onTextChanged) {
            editorUrl += '&changeEvent=1'
        }
        if (props.onCaretPositionChanged) {
            editorUrl += '&caretEvent=1'
        }
        if (props.onBlur) {
            editorUrl += '&blurEvent=1'
        }
        return editorUrl
    }

    shouldComponentUpdate(nextProps: Readonly<EditorPropsType>): boolean {
        if (nextProps.text !== this.state.text) {
            this.setText(nextProps.text)
        }
        if (this.props.readonly !== nextProps.readonly) {
            this.setReadOnly(nextProps.readonly)
        }
        return (
            this.props.className != nextProps.className ||
            this.props.title != nextProps.title ||
            this.buildEditorUrl(this.props) !== this.buildEditorUrl(nextProps)
        )
    }

    render() {
        const props = this.props
        const editorUrl = this.buildEditorUrl(props)
        return <iframe src={editorUrl} width="100%" className={props.className} title={props.title} ref={this.iframe} />
    }
}
