import { PageType, ReduxAction } from '../../types/reducers'
import axios, { AxiosPromise, AxiosResponse } from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export enum OCR_ACTIONS {
    CREATE_DOCUMENT = 'documentsActions/createDocuments',
    CREATE_DOCUMENT_LOADING = 'documentsActions/createDocumentsLoading',
    CREATE_DOCUMENT_LOADED = 'documentsActions/createDocumentsSuccess',
    CREATE_DOCUMENT_ERROR = 'documentsActions/createDocumentsError',
    LIST_DOCUMENTS = 'documentsActions/listDocuments',
    LIST_DOCUMENTS_LOADING = 'documentsActions/listDocumentsLoading',
    LIST_DOCUMENTS_LOADED = 'documentsActions/listDocumentsSuccess',
    LIST_DOCUMENTS_ERROR = 'documentsActions/listDocumentsError',
    GET_DOCUMENT_DETAILS = 'documentsActions/getDocumentDetails',
    GET_DOCUMENT_DETAILS_LOADING = 'documentsActions/getDocumentDetailsLoading',
    GET_DOCUMENT_DETAILS_LOADED = 'documentsActions/getDocumentDetailsSuccess',
    LIST_DOCUMENT_DETAILS_ERROR = 'documentsActions/getDocumentDetailsError',
    GET_PAGE_DETAILS = 'documentsActions/getPageDetails',
    GET_PAGE_DETAILS_LOADING = 'documentsActions/getPageDetailsLoading',
    GET_PAGE_DETAILS_LOADED = 'documentsActions/getPageDetailsSuccess',
    GET_PAGE_DETAILS_ERROR = 'documentsActions/getPageDetailsError',
    SET_PAGE_VIEW_MODE = 'documentsActions/setPageViewMode',
    SAVE_TRANSCRIPTION = 'documentsActions/saveTranscription',
    SAVE_TRANSCRIPTION_LOADING = 'documentsActions/saveTranscriptionLoading',
    SAVE_TRANSCRIPTION_LOADED = 'documentsActions/saveTranscriptionSuccess',
    SAVE_TRANSCRIPTION_ERROR = 'documentsActions/saveTranscriptionError',
    SAVE_DRAFT = 'documentsActions/saveDraft',
}

export const listDocumentsAction: ReduxAction<Promise<AxiosPromise>> = () => {
    return {
        type: OCR_ACTIONS.LIST_DOCUMENTS,
        payload: axios.get('/b/ocr/api/documents/'),
    }
}

export const getDocumentDetails: ReduxAction<Promise<AxiosPromise>> = (data) => {
    const id: number = data?.id
    return {
        type: OCR_ACTIONS.GET_DOCUMENT_DETAILS,
        payload: axios.get(`/b/ocr/api/documents/${id}/`),
    }
}

export const getPageDetails: ReduxAction<Promise<AxiosPromise>> = (pageId) => {
    return {
        type: OCR_ACTIONS.GET_PAGE_DETAILS,
        payload: axios.get(`/b/ocr/api/pages/${pageId}/`),
        meta: { pageId },
    }
}

export const setPageViewMode: ReduxAction<any> = (mode) => {
    return {
        type: OCR_ACTIONS.SET_PAGE_VIEW_MODE,
        payload: mode,
    }
}

export const saveTranscription: ReduxAction<Promise<AxiosPromise>> = (data: {
    text: string
    status: string
    page: PageType
}) => {
    const { page, text, status } = data
    return {
        type: OCR_ACTIONS.SAVE_TRANSCRIPTION,
        payload: axios.post(`/b/ocr/api/pages/${page.pk}/submit_transcription/`, { text, status }),
        meta: { pageId: page.pk, text, status },
    }
}

export const saveDraft: any = (data: { pageId: number; draft: string }) => {
    return {
        type: OCR_ACTIONS.SAVE_DRAFT,
        payload: data,
    }
}

export const uploadDocumentAction: ReduxAction<Promise<AxiosResponse>> = (data: {
    document: File
    title: string
    image: File
}) => {
    const formData = new FormData()
    formData.append('filename', data.document)
    formData.append('image', data.image)
    formData.append('title', data.title)

    const payload = axios.post('/b/ocr/api/documents/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return {
        type: OCR_ACTIONS.CREATE_DOCUMENT,
        payload,
    }
}
