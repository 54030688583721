import { ReduxAction } from '../../types/reducers'
import axios, { AxiosPromise } from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export enum ACCOUNTS_ACTIONS {
    REGISTER_USER = 'Users_Actions/registerUser',
    REGISTER_USER_LOADING = 'Users_Actions/registerUserLoading',
    REGISTER_USER_LOADED = 'Users_Actions/registerUserSuccess',
    REGISTER_USER_ERROR = 'Users_Actions/registerUserError',
    LOGIN_USER = 'Users_Actions/loginUser',
    LOGIN_USER_LOADING = 'Users_Actions/loginUserLoading',
    LOGIN_USER_LOADED = 'Users_Actions/loginUserSuccess',
    LOGIN_USER_ERROR = 'Users_Actions/loginUserError',
    SHOW_LOGIN_FORM = 'Users_Actions/showLoginForm',
    LOGOUT_USER = 'Users_Actions/logoutUser',
    LOGOUT_USER_LOADING = 'Users_Actions/logoutUserLoading',
    LOGOUT_USER_LOADED = 'Users_Actions/logoutUserSuccess',
    LOGOUT_USER_ERROR = 'Users_Actions/logoutUserError',
    GET_USER_INFO = 'Users_Actions/getUserInfo',
    GET_USER_INFO_LOADING = 'Users_Actions/getUserInfoLoading',
    GET_USER_INFO_LOADED = 'Users_Actions/getUserInfoSuccess',
    GET_USER_INFO_ERROR = 'Users_Actions/getUserInfoError',
    UPDATE_USER_PROFILE = 'Users_Actions/updateUserProfile',
    UPDATE_USER_PROFILE_LOADING = 'Users_Actions/updateUserProfileLoading',
    UPDATE_USER_PROFILE_LOADED = 'Users_Actions/updateUserProfileSuccess',
    UPDATE_USER_PROFILE_ERROR = 'Users_Actions/updateUserProfileError',
}

export const registerUser: ReduxAction<Promise<AxiosPromise>> = (data: { username: string; password: string }) => {
    const { username, password } = data
    return {
        type: ACCOUNTS_ACTIONS.REGISTER_USER,
        payload: axios.post('/b/accounts/api/users/register/', { username: username, password: password }),
    }
}

export const showLoginForm: ReduxAction<any> = (show = true) => {
    return {
        type: ACCOUNTS_ACTIONS.SHOW_LOGIN_FORM,
        payload: { show },
    }
}
export const loginUser: ReduxAction<Promise<AxiosPromise>> = (data: { username: string; password: string }) => {
    const { username, password } = data
    return {
        type: ACCOUNTS_ACTIONS.LOGIN_USER,
        payload: axios.post('/b/accounts/api/users/login/', { username: username, password: password }),
    }
}
export const logoutUser: ReduxAction<Promise<AxiosPromise>> = () => {
    return {
        type: ACCOUNTS_ACTIONS.LOGOUT_USER,
        payload: axios.get('/b/accounts/api/users/logout/'),
    }
}

export const getUserInfoAction: ReduxAction<Promise<AxiosPromise>> = (fullDetails: boolean) => {
    let url = '/b/accounts/api/users/me/'

    if (!fullDetails) {
        url += '?fields=is_active,is_staff,username'
    }

    return {
        type: ACCOUNTS_ACTIONS.GET_USER_INFO,
        payload: axios.get(url),
    }
}
export const updateProfile: ReduxAction<Promise<AxiosPromise>> = (data: {
    username: string
    email: string
    firstName: string
    lastName: string
    aboutMe: string
}) => {
    const { username, email, firstName, lastName, aboutMe } = data
    return {
        type: ACCOUNTS_ACTIONS.UPDATE_USER_PROFILE,
        payload: axios.put('/b/accounts/api/users/me/', {
            username: username,
            email: email,
            first_name: firstName,
            last_name: lastName,
            about_me: aboutMe,
        }),
    }
}
