import React, { useEffect, useState } from 'react'
import { RootState } from '../../store/reducers/store'
import { useDispatch, useSelector } from 'react-redux'
import { updateLineTranslation } from '../../store/actions/translations'
import { useParams } from 'react-router-dom'

interface TranslationLineProps {
    lineIndex: number
}

const TranslationLine: React.FC<TranslationLineProps> = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const pageID = params.page as string
    const line = useSelector((state: RootState) => state.translations[pageID].lines[props.lineIndex])
    const [translation, setTranslation] = useState(line.translation || '')
    const [comment, setComment] = useState(line.comment || '')
    useEffect(() => {
        setComment(line.comment || '')
        setTranslation(line.translation || '')
    }, [line.translation, line.comment])
    const updateTranslation = () => {
        dispatch(updateLineTranslation(props.lineIndex, translation, comment, pageID))
    }
    return (
        <div className="row">
            <h4 className={'col-12 mb-2 ' + (line.translation ? 'text-primary' : '')}>{line.text}</h4>
            <div className="col-12 mb-2">
                <input
                    type="text"
                    className="form-control"
                    value={translation}
                    placeholder="Translation"
                    onChange={(e) => setTranslation(e.target.value)}
                    onBlur={updateTranslation}
                />
            </div>
            <div className="col-12 mb-2">
                <input
                    type="text"
                    className="form-control"
                    value={comment}
                    placeholder="Comments/Notes"
                    tabIndex={-1}
                    onChange={(e) => setComment(e.target.value)}
                    onBlur={updateTranslation}
                />
            </div>
        </div>
    )
}

export default TranslationLine
