import { ACCOUNTS_ACTIONS } from '../actions/accounts'
import { createReducer } from '@reduxjs/toolkit'
import { ReduxActionData, AccountsStoreType } from '../../types/reducers'

const initialState: AccountsStoreType = {
    userInfo: {
        is_active: null,
        is_staff: null,
        error: null,
        loading: null,
    },
    login: {
        loading: null,
        error: null,
        showLoginForm: false,
    },
    register: {
        loading: null,
        error: null,
    },
    logout: {
        loading: null,
        error: null,
    },
    updateProfile: {
        loading: null,
        error: null,
    },
}
const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(ACCOUNTS_ACTIONS.REGISTER_USER_LOADING, (state) => {
            state.register.loading = true
            state.register.error = null
        })
        .addCase(ACCOUNTS_ACTIONS.REGISTER_USER_LOADED, (state) => {
            state.register.loading = false
            state.register.success = true
        })
        .addCase(ACCOUNTS_ACTIONS.REGISTER_USER_ERROR, (state, action: ReduxActionData<any>) => {
            state.register.loading = false
            state.register.error = action.payload.response.data
        })
        .addCase(ACCOUNTS_ACTIONS.LOGIN_USER_LOADING, (state) => {
            state.login.loading = true
            state.login.error = null
        })
        .addCase(ACCOUNTS_ACTIONS.LOGIN_USER_LOADED, (state, action: ReduxActionData<any>) => {
            state.login.loading = false
            state.login.success = true
            state.login.showLoginForm = false
            state.userInfo = action.payload.data
        })
        .addCase(ACCOUNTS_ACTIONS.LOGIN_USER_ERROR, (state, action: ReduxActionData<any>) => {
            state.login.loading = false
            state.login.error = action.payload.response.data
        })
        .addCase(ACCOUNTS_ACTIONS.SHOW_LOGIN_FORM, (state, action: ReduxActionData<any>) => {
            state.login.showLoginForm = action.payload.show
        })
        .addCase(ACCOUNTS_ACTIONS.LOGOUT_USER_LOADING, (state) => {
            state.logout.loading = true
            state.logout.error = null
        })
        .addCase(ACCOUNTS_ACTIONS.LOGOUT_USER_LOADED, (state) => {
            state.userInfo = { ...initialState.userInfo }
            state.logout.success = true
        })
        .addCase(ACCOUNTS_ACTIONS.LOGOUT_USER_ERROR, (state, action: ReduxActionData<any>) => {
            state.logout.loading = false
            state.logout.error = action.payload.response.data
        })
        .addCase(ACCOUNTS_ACTIONS.UPDATE_USER_PROFILE_LOADING, (state) => {
            state.updateProfile.loading = true
            state.updateProfile.error = null
        })
        .addCase(ACCOUNTS_ACTIONS.UPDATE_USER_PROFILE_LOADED, (state, action: ReduxActionData<any>) => {
            state.updateProfile.loading = false
            state.updateProfile.success = true
            state.userInfo = action.payload.data
        })
        .addCase(ACCOUNTS_ACTIONS.UPDATE_USER_PROFILE_ERROR, (state, action: ReduxActionData<any>) => {
            state.updateProfile.loading = false
            state.updateProfile.error = action.payload.response.data
        })
        .addCase(ACCOUNTS_ACTIONS.GET_USER_INFO_LOADING, (state) => {
            state.userInfo.error = null
            state.userInfo.loading = true
        })
        .addCase(ACCOUNTS_ACTIONS.GET_USER_INFO_LOADED, (state, action: ReduxActionData<any>) => {
            state.userInfo = action.payload.data
            state.userInfo.loading = false
        })
        .addCase(ACCOUNTS_ACTIONS.GET_USER_INFO_ERROR, (state, action: ReduxActionData<any>) => {
            state.userInfo.error = action.payload.response.data
            state.userInfo.loading = false
        })
)

export default dataState
