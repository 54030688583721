import React, { useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { TextOrganizer } from '../components/translation/TextOrganizer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/reducers/store'
import { setActiveTab } from '../store/actions/translations'
import { TextTranslator } from '../components/translation/TextTranslator'
import { BackToPageDetailsButton } from '../components/documents/PageNavigation'
import { setActiveSection, setPageDetails } from '../store/actions/menu'
import { MenuSections } from '../types/reducers'
import { useParams } from 'react-router-dom'
import * as actions from '../store/actions/translations'
import { ErrorHandling } from '../components/general/ErrorHandling'

const Translation = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const pageID: string = params.page!
    const translations = useSelector((state: RootState) => state.translations[pageID])
    const activeTab = useSelector((state: RootState) => state.translations[params.page as string]?.activeTab)
    const translationAvailable = !!translations
    useEffect(() => {
        dispatch(setPageDetails({ pageID: params.page || null, documentID: params.id }))
        dispatch(setActiveSection(MenuSections.Translation))
        if (!translationAvailable) {
            dispatch(actions.getTranslations(params.page || null))
        }
    }, [dispatch, params, translationAvailable])
    const onTabChange = (tab: string | null) => {
        if (tab) {
            dispatch(setActiveTab(tab, pageID))
        }
    }
    if (!translations) {
        return <ErrorHandling error="" loading={true} />
    }
    return (
        <div>
            <h1 className="m-3 text-center">Translations</h1>
            <BackToPageDetailsButton />
            <Tabs activeKey={activeTab} className="mb-3" onSelect={onTabChange}>
                <Tab eventKey="organize" title="Text organization">
                    <TextOrganizer />
                </Tab>
                <Tab eventKey="translation" title="Translation">
                    <TextTranslator />
                </Tab>
            </Tabs>
        </div>
    )
}

export default Translation
