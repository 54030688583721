import React from 'react'
import { FieldProps } from 'formik'

interface FileUploadInputProps extends FieldProps<string> {
    setFieldValue: (name: string, value: any) => void
}

const FileUploadInput = (props: FileUploadInputProps) => {
    const handleUploadChange = (event: { target: HTMLInputElement }) => {
        const reader = new FileReader()
        const file = event.target.files![0]

        if (file) {
            reader.readAsDataURL(file)
            props.setFieldValue(props.field.name, file)
        }
    }

    return (
        <input
            name={props.field.name}
            type="file"
            className="form-control"
            onChange={handleUploadChange}
            onBlur={props.field.onBlur}
        />
    )
}

export default FileUploadInput
