import React from 'react'
import { ErrorHandling, FormikServerErrors } from '../general/ErrorHandling'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as actions from '../../store/actions/accounts'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import { showLoginForm } from '../../store/actions/accounts'

export const LoginModal: React.FC = () => {
    const dispatch = useDispatch()
    const login = useSelector((state: RootState) => state.accounts.login)
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .matches(/^[a-zA-Z0-9_]+$/, 'Enter a valid username. Username may contain only letters, numbers, and _ characters.')
            .required('Required'),
        password: Yup.string().min(8, 'Must be 8 characters or more').required('Required'),
    })
    return (
        <Modal show={login.showLoginForm} onHide={() => dispatch(showLoginForm(false))} centered={true}>
            <div className="d-flex justify-content-center m-3">
                <ErrorHandling error={login.error} loading={login.loading} />
            </div>
            <Formik
                initialValues={{
                    username: '',
                    password: '',
                }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                    dispatch(actions.loginUser({ username: values.username, password: values.password }))
                }}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form className="p-4">
                        <FormikServerErrors errors={login.error} loading={login.loading} />
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="username">
                                Username
                            </label>
                            <Field name="username" id="username" className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`} />

                            <ErrorMessage name="username" component="div" className="text-danger" />
                        </div>
                        <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="password">
                                Password
                            </label>
                            <Field
                                name="password"
                                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                type="password"
                            />
                            <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>

                        <div className="form-outline mt-5 text-center">
                            <button className={'btn btn-primary ' + (isSubmitting ? 'disabled' : '')} type="submit">
                                Log in
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}
