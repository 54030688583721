import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/reducers/store'
import { useParams } from 'react-router-dom'
import * as actions from '../store/actions/documents'
import { ErrorHandling } from '../components/general/ErrorHandling'
import PageNavigation from '../components/documents/PageNavigation'
import { Page } from '../components/documents/Page'
import { createSelector } from '@reduxjs/toolkit'
import { setPageDetails, setActiveSection } from '../store/actions/menu'
import { MenuSections } from '../types/reducers'

export const Document: React.FC = () => {
    const dispatch = useDispatch()
    const { loading, error, documentID, title } = useSelector(
        createSelector(
            (state: RootState) => state?.documents?.curDocument,
            (document) => {
                if (!document) return { loading: null, error: null, documentID: null }
                return {
                    loading: document.loading,
                    error: document.error,
                    documentID: document.pk,
                    title: document.title,
                }
            }
        )
    )
    const params = useParams()
    const curPageId: string = params.page || ''
    useEffect(() => {
        if (loading === null) {
            dispatch(actions.getDocumentDetails({ id: params.id }))
        }
    }, [dispatch, params.id, loading])

    useEffect(() => {
        dispatch(setPageDetails({ pageID: params.page || null, documentID: params.id }))
        dispatch(setActiveSection(MenuSections.OCRPage))
    }, [dispatch, params])

    if (documentID === 0) {
        return <ErrorHandling error={error} loading={loading} />
    }
    return (
        <div>
            <h1 className="text-center m-3 text-capitalize">{title?.toLowerCase() || ''}</h1>
            <ErrorHandling error={error} loading={loading} />
            <div className="container-fluid">
                <PageNavigation pageId={curPageId} />
                {curPageId ? <Page pageId={curPageId} /> : null}
            </div>
        </div>
    )
}
