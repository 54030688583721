import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { uploadDocumentAction } from '../../store/actions/documents'
import { Button, Form, Modal } from 'react-bootstrap'
import { ErrorMessage, Field, Formik } from 'formik'
import FileUploadInput from '../general/FileUpload'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/reducers/store'
import { ErrorHandling, FormikServerErrors } from '../general/ErrorHandling'

export const AddDocument: React.FC = () => {
    const initialValues = {
        title: '',
        document: null,
        image: null,
    }
    const [showModal, setShowModal] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const dispatch = useDispatch()
    const createDocument = useSelector((state: RootState) => state.documents.createDocument)

    useEffect(() => {
        if (formSubmitted && createDocument.loading === false && !createDocument.error) {
            handleCloseModal()
            setFormSubmitted(false)
        }
    }, [formSubmitted, createDocument])

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Title is required'),
        document: Yup.mixed()
            .required('Document is required')
            .test('file-type', 'Invalid file format', (value: any) => {
                if (value) {
                    return value.type === 'application/pdf'
                }
                return false
            }),
        image: Yup.mixed()
            .required('Preview Image is required')
            .test('file-type', 'Invalid file format', (value: any) => {
                if (value) {
                    return value.type.startsWith('image/')
                }
                return true
            }),
    })

    const handleAddDocument = () => {
        setShowModal(true)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    const handleSubmit = async (values: { title: string; document: File | null; image: File | null }) => {
        dispatch(uploadDocumentAction(values))
        setFormSubmitted(true)
    }

    return (
        <div>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorHandling error={createDocument.error} loading={createDocument.loading} />
                    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ handleSubmit, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormikServerErrors errors={createDocument.error} loading={createDocument.loading} />
                                <Form.Group controlId="title">
                                    <Form.Label>Title</Form.Label>
                                    <Field name="title" as={Form.Control} />
                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                </Form.Group>
                                <Form.Group controlId="document">
                                    <Form.Label>Document</Form.Label>
                                    <Field name="document" component={FileUploadInput} setFieldValue={setFieldValue} />
                                    <ErrorMessage name="document" component="div" className="text-danger" />
                                    <Form.Text>Upload a PDF file.</Form.Text>
                                </Form.Group>
                                <Form.Group controlId="image">
                                    <Form.Label>Preview Image</Form.Label>
                                    <Field name="image" component={FileUploadInput} setFieldValue={setFieldValue} />
                                    <ErrorMessage name="image" component="div" className="text-danger" />
                                    <Form.Text>Upload an image file.</Form.Text>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Save
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Button variant="primary" onClick={handleAddDocument}>
                Add Document
            </Button>
        </div>
    )
}
