import { findBestMatch } from 'string-similarity'
import { PAGE_TRANSLATION_ACTIONS } from '../actions/translations'
import { createReducer } from '@reduxjs/toolkit'
import { TranslationStoreType, ReduxActionData, LineTranslationType } from '../../types/reducers'

const initialState: TranslationStoreType = {}

const originalText = (lines: LineTranslationType[]) => lines.map((line) => line.text).join('\n')

const dataState = createReducer(initialState, (builder) =>
    builder
        .addCase(PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_LOADING, (state, action: ReduxActionData<any>) => {
            state[action.meta.pageId] = {
                loading: true,
                lines: [],
                activeTab: 'organize',
                nextPageLines: '',
                error: null,
                originalText: '',
                submitTranslation: { loading: false, error: null, success: null },
            }
        })
        .addCase(
            PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_LOADED,
            (state = initialState, action: ReduxActionData<any>) => {
                const page = state[action.meta.pageId]
                page.loading = false
                page.error = null
                page.lines = action.payload.data.lines
                page.originalText = originalText(page.lines)
                page.nextPageLines = action.payload.data.next_page_text
            }
        )
        .addCase(PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS_ERROR, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.loading = false
            page.error = action.payload.response.data
        })
        .addCase(PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_LOADING, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.submitTranslation.error = null
            page.submitTranslation.success = null
            page.submitTranslation.loading = true
        })
        .addCase(
            PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_LOADED,
            (state = initialState, action: ReduxActionData<any>) => {
                const page = state[action.meta.pageId]
                page.originalText = originalText(page.lines)
                page.lines = action.meta.lines
                page.submitTranslation.loading = false
                page.submitTranslation.success = true
            }
        )

        .addCase(PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS_ERROR, (state, action: ReduxActionData<any>) => {
            const page = state[action.meta.pageId]
            page.submitTranslation.error = action.payload.response.data
            page.submitTranslation.loading = false
        })
        .addCase(PAGE_TRANSLATION_ACTIONS.SET_ACTIVE_TAB, (state, action: ReduxActionData<any>) => {
            const pageId = action.meta.pageId
            state[pageId].activeTab = action.payload.activeTab
        })
        .addCase(
            PAGE_TRANSLATION_ACTIONS.SET_ORGANIZED_TRANSLATION_TEXT,
            (state = initialState, action: ReduxActionData<any>) => {
                const { organizedText } = action.payload
                const page = state[action.meta.pageId]
                // build a map of line text and line translation
                const translatedLines = new Map(
                    page.lines.filter((line) => line.translation || line.comment).map((line) => [line.text, line])
                )
                // use this map to decide which line gets which translation
                const originalLines = organizedText.split('\n')
                const finalLines = originalLines.map((organizedLine: string) => {
                    const translatedLine = translatedLines.get(organizedLine)
                    if (translatedLine) {
                        translatedLines.delete(organizedLine)
                        return translatedLine
                    }

                    return {
                        text: organizedLine,
                        translation: '',
                        comment: '',
                    }
                })
                // if we still have translation that didn't match any line, try to find best match
                for (const line of translatedLines.values()) {
                    const bestMatch = findBestMatch(line.text, originalLines)
                    if (bestMatch.bestMatch.rating > 0.7) {
                        const index = bestMatch.bestMatchIndex
                        const originalLine = finalLines[index]
                        originalLine.comment = originalLine.comment || line.comment
                        originalLine.translation = originalLine.translation || line.translation
                    }
                }
                page.lines = finalLines
                page.error = null
            }
        )
        .addCase(
            PAGE_TRANSLATION_ACTIONS.UPDATE_LINE_TRANSLATION,
            (state = initialState, action: ReduxActionData<any>) => {
                const { lineIndex, translation, comment } = action.payload
                const page = state[action.meta.pageId]
                page.submitTranslation.success = null
                page.lines[lineIndex] = {
                    ...page.lines[lineIndex],
                    translation,
                    comment,
                }
            }
        )
)

export default dataState
