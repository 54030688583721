import { JSX, useEffect, useState } from 'react'

type ProgressiveImageProps = {
    src: string
    placeholder: string
    onLoad?: () => void
    children: {
        (src: string): JSX.Element
    }
}

const ProgressiveImage = (props: ProgressiveImageProps) => {
    const originalSrc = props.src
    const { onLoad, placeholder } = props
    const [src, setSrc] = useState(originalSrc)

    useEffect(() => {
        const img = new Image()
        img.src = originalSrc
        img.onload = () => {
            setSrc(originalSrc)
            img.remove()
            if (onLoad) onLoad()
        }
    }, [originalSrc, placeholder, onLoad])

    return props.children(src)
}

export default ProgressiveImage
