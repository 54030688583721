import { ReduxAction } from '../../types/reducers'
import axios, { AxiosPromise } from 'axios'
import { LineTranslationType } from '../../types/reducers'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

export enum PAGE_TRANSLATION_ACTIONS {
    GET_TRANSLATIONS = 'TranslationActions/getTranslations',
    GET_TRANSLATIONS_LOADING = 'TranslationActions/getTranslationsLoading',
    GET_TRANSLATIONS_LOADED = 'TranslationActions/getTranslationsSuccess',
    GET_TRANSLATIONS_ERROR = 'TranslationActions/getTranslationsError',
    SUBMIT_TRANSLATIONS = 'TranslationActions/submitTranslations',
    SUBMIT_TRANSLATIONS_LOADING = 'TranslationActions/submitTranslationsLoading',
    SUBMIT_TRANSLATIONS_LOADED = 'TranslationActions/submitTranslationsSuccess',
    SUBMIT_TRANSLATIONS_ERROR = 'TranslationActions/submitTranslationsError',
    SET_ORGANIZED_TRANSLATION_TEXT = 'TranslationActions/setOrganizedTranslationText',
    SET_ACTIVE_TAB = 'TranslationActions/setActiveTab',
    UPDATE_LINE_TRANSLATION = 'TranslationActions/updateLineTranslation',
}

export const getTranslations: ReduxAction<Promise<AxiosPromise>> = (pageId) => {
    return {
        type: PAGE_TRANSLATION_ACTIONS.GET_TRANSLATIONS,
        payload: axios.get(`/b/ocr/api/pages/${pageId}/translations`),
        meta: { pageId },
    }
}
export const submitTranslations: ReduxAction<Promise<AxiosPromise>> = (data: {
    pageId: number
    lines: LineTranslationType
}) => {
    const { pageId, lines } = data
    return {
        type: PAGE_TRANSLATION_ACTIONS.SUBMIT_TRANSLATIONS,
        payload: axios.post(`/b/ocr/api/pages/${pageId}/translations/`, lines),
        meta: { pageId, lines },
    }
}
export const setOrganizedTranslationText = (organizedText: string, pageId: string) => ({
    type: PAGE_TRANSLATION_ACTIONS.SET_ORGANIZED_TRANSLATION_TEXT,
    payload: { organizedText },
    meta: { pageId },
})

export const setActiveTab = (activeTab: string, pageId: string) => ({
    type: PAGE_TRANSLATION_ACTIONS.SET_ACTIVE_TAB,
    payload: { activeTab },
    meta: { pageId },
})

export const updateLineTranslation = (lineIndex: number, translation: string, comment: string, pageId: string) => ({
    type: PAGE_TRANSLATION_ACTIONS.UPDATE_LINE_TRANSLATION,
    payload: { lineIndex, translation, comment },
    meta: { pageId },
})
